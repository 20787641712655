/** 
* To call in a certain file you will need to state the path to the file
* and then ensure it has `//= ` before the path. 
*
* State all JS files in cascading order
*
* Remember to remove the `=` from any path that isn't in use and to remove any unwanted JS
*/

// Foundation 5
//= include ../vendor/foundation/js/vendor/fastclick.js
//= include ../vendor/foundation/js/vendor/jquery.cookie.js
//= include ../vendor/foundation/js/vendor/placeholder.js
//= include ../vendor/foundation/js/foundation/foundation.js

// Foundation Components
//= include ../vendor/foundation/js/foundation/foundation.abide.js
//= include ../vendor/foundation/js/foundation/foundation.accordion.js
// include ../vendor/foundation/js/foundation/foundation.alert.js
// include ../vendor/foundation/js/foundation/foundation.clearing.js
//= include ../vendor/foundation/js/foundation/foundation.dropdown.js
//= include ../vendor/foundation/js/foundation/foundation.equalizer.js
// include ../vendor/foundation/js/foundation/foundation.interchange.js
// include ../vendor/foundation/js/foundation/foundation.joyride.js
// include ../vendor/foundation/js/foundation/foundation.magellan.js
// include ../vendor/foundation/js/foundation/foundation.offcanvas.js
// include ../vendor/foundation/js/foundation/foundation.orbit.js
//= include ../vendor/foundation/js/foundation/foundation.reveal.js
// include ../vendor/foundation/js/foundation/foundation.slider.js
// include ../vendor/foundation/js/foundation/foundation.tab.js
// include ../vendor/foundation/js/foundation/foundation.tooltip.js
// include ../vendor/foundation/js/foundation/foundation.topbar.js

//= include ../vendor/smooth-scroll/smooth-scroll.js
//= include ../vendor/slick-carousel/slick/slick.js
//= include ../vendor/jquery-backstretch/jquery.backstretch.js
//= include ../vendor/imagesloaded/imagesloaded.pkgd.js
//= include ../vendor/jquery-smartresize/jquery.throttledresize.js
//= include ../vendor/masonry/dist/masonry.pkgd.js
//= include ../vendor/jQuery.mmenu/dist/core/js/jquery.mmenu.min.js
//= include ../vendor/lightbox2/dist/js/lightbox.js

$(document).ready(function(){

    $(document).foundation();
      
      $('[data-backstretch-image]').each(function(){

        var $el = $(this);
        var config = $el.data('backstretchConfig') || {};
   
        $el.backstretch($el.data('backstretchImage'), config);
    }); 


    /* *
    * Backstretch
    */
    // Backstretch on a block element
    $(".banner-default").backstretch("/assets/images/banner.jpg");
    $(".team").backstretch("/assets/images/team.jpg");
    $(".team-inner").backstretch("/assets/images/team-inner.jpg");
    $(".team-banner").backstretch("/assets/images/trainer.jpg");
    $(".casestudy-inner").backstretch("/assets/images/casestudy-inner.jpg");
    $(".case-studies").backstretch("/assets/images/casestudy.jpg");
    $(".casestudy-enquiry").backstretch("assets/images/masthead.jpg");
    $(".news-banner").backstretch("/assets/images/news-banner.jpg");
    $(".news-inner").backstretch("/assets/images/news-inner.jpg")
    $(".prices-banner").backstretch("/assets/images/prices.jpg");
    $(".service-banner").backstretch("/assets/images/service-banner.jpg");


    // Full width backstretch slideshow
    // $.backstretch([
    //     "/assets/images/backstretch_block_slider_1.jpg",
    //     "/assets/images/backstretch_block_slider_2.jpg",
    //     "/assets/images/masthead.jpg",
    // ], {
    //     duration: 3000, 
    //     fade: 750
    // });

    // Block element backstretch slideshow
    // $("header").backstretch([
    //     "/assets/images/backstretch_block_slider_1.jpg",
    //     "/assets/images/backstretch_block_slider_2.jpg",
    // ], {
    //     duration: 6000, 
    //     fade: 750
    // });

    // Backstretch click events
    $(".clickOne").click(function(e) {
        e.preventDefault();
        $(".click_one").backstretch('/assets/images/click_1.jpg');
    });

    $(".clickTwo").click(function(e) {
        e.preventDefault();
        $(".click_one").backstretch('/assets/images/click_2.jpg');
    });



    /**
    * Slick Slider
    */
    $('.treatment_slider').slick({
    	// options
    	slidesToShow: 4,
    	slidesToScroll: 4,
    	autoplay: true,
    	dots: false,
    	arrows: true,
    	speed: 500,
    	pauseonhover: true,
    	autoplaySpeed: 8000,
    	  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
    });

     $('.gallery_slider').slick({
        // options
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        dots: true,
        arrows: true,
        autoplaySpeed: 3000,
        adaptiveHeight: true
    });

    /**
    * Mmenu
    */
    $("#my-menu").mmenu();

	/**
	* Jquery Placeholder
	*/
  	$('input, textarea').placeholder();

	/**
	* Map Scroll
	*/
	// set the pointer events to none on doc ready
  	$('.map iframe').addClass('scrolloff'); 
  
  	$('.map').on('click', function() {
		// set the pointer events true on click
		$('.map iframe').removeClass('scrolloff');
  	});

 	 // you want to disable pointer events when the mouse leave the canvas area;
  	$( ".map" ).mouseleave(function() {
		// set the pointer events to none when mouse leaves the map area
		$('.map iframe').addClass('scrolloff'); 
  	});
	
   
	/**
	* Masonry - with smart resize and images loaded JS
	*/
	$(window).on("throttledresize", function( event ){
		if ($(window).width() > 641) {
			/**
			* Masonry
			*/
			var $grid = $('.grid').imagesLoaded( function() {
			// init Masonry after all images have loaded
				$grid.masonry({
					// options...
					itemSelector: '.grid-item',
				});
			});
		}
	});

	$(window).trigger('resize');
});